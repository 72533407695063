<template>
  <div class="edp-backend">
    <edp-backend-user />

    <section class="edp-backend-middle">
      <edp-backend-menu />

      <!-- content -->
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <mainTitle></mainTitle>

          <section class="edp-backend-conditionSearch">
            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendReport.reportName") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="100"
                  placeholder="请输入"
                  v-model="searchParams.reportName"
                  clearable
                />
              </div>
            </div>

            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendReport.memberName") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="100"
                  placeholder="请输入"
                  v-model="searchParams.fullName"
                  clearable
                />
              </div>
            </div>

            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendReport.memberCdsId") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="100"
                  placeholder="请输入"
                  v-model="searchParams.cdsid"
                  clearable
                />
              </div>
            </div>

            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendReport.updateMemberName") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="100"
                  placeholder="请输入"
                  v-model="searchParams.createFullName"
                  clearable
                />
              </div>
            </div>

            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendReport.updateMemberCdsId") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="100"
                  placeholder="请输入"
                  v-model="searchParams.createBy"
                  clearable
                />
              </div>
            </div>

            <div class="edp-backend-conditionSearch-square" style="width: 57%">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendReport.createTime") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-date-picker
                  v-model="timeRange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetimerange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
            </div>

            <div
              class="edp-backend-conditionSearch-square conditionSearch-square-last"
            >
              <el-button size="small" @click="clearSearchParams">{{
                $t("edpBackendCommon.clear")
              }}</el-button>
              <el-button size="small" type="primary" @click="pageInit(1)">{{
                $t("edpBackendCommon.search")
              }}</el-button>
            </div>
          </section>

          <section class="edp-backend-common-table">
            <div class="table-headControlLine">
              <div>
                <el-button type="primary" size="small" @click="uploadDoc">
                  {{ $t("edpBackendReport.upload") }}</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  @click="reportHistoryDetail_outputImage"
                  >{{ $t("edpBackendReport.outputImage") }}</el-button
                >
                <el-button size="small" @click="deleteRow">
                  {{ $t("edpBackendCommon.delete") }}</el-button
                >
              </div>

              <el-button type="text" @click="seeHistory">
                {{ $t("edpBackendReport.uploadHistory") }}</el-button
              >
            </div>

            <el-table
              stripe
              :data="pageList"
              style="width: 100%"
              v-loading="loading"
            >
              <el-table-column fixed width="50">
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.selected"
                    @change="handleSelectionChange(scope.row)"
                  />
                </template>
              </el-table-column>
              <el-table-column
                fixed
                prop="reportName"
                :label="$t('edpBackendReport.reportName')"
              >
              </el-table-column>

              <el-table-column
                prop="fullName"
                :label="$t('edpBackendReport.memberName')"
              >
              </el-table-column>

              <el-table-column
                prop="cdsid"
                :label="$t('edpBackendReport.memberCdsId')"
              ></el-table-column>

              <el-table-column
                prop="createFullName"
                :label="$t('edpBackendReport.updateMemberName')"
              ></el-table-column>

              <el-table-column
                prop="createBy"
                :label="$t('edpBackendReport.updateMemberCdsId')"
              ></el-table-column>

              <el-table-column
                width="180"
                prop="notEvaluated"
                :label="$t('edpBackendReport.createTime')"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime || "--" }}
                </template>
              </el-table-column>

              <el-table-column
                :label="$t('edpBackendReport.control')"
                fixed="right"
                width="110"
              >
                <template slot-scope="scope">
                  <el-button type="text" @click="deleteRow(scope.row)">
                    {{ $t("edpBackendCommon.delete") }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>

            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="pageTotal"
            >
            </el-pagination>
          </section>
        </div>
      </section>
      <!-- content end -->
    </section>

    <el-dialog
      class="edp-backend-dialogEl"
      v-if="dialogVisible"
      :title="$t('edpBackendReport.upload')"
      :visible.sync="dialogVisible"
      width="700px"
    >
      <edp-upload @success="uploadSuccessFn" />

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{
          $t("edpBackendCommon.canale")
        }}</el-button>
        <el-button
          type="primary"
          @click="dialogVisible_submit"
          :disabled="!subStatus"
          >{{ $t("edpBackendCommon.define") }}</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      class="edp-backend-dialogEl"
      v-if="dialogVisible_history"
      :title="$t('edpBackendReport.uploadHistory')"
      :visible.sync="dialogVisible_history"
      width="1000px"
    >
      <section class="edp-backend-common-table">
        <el-table
          stripe
          :data="historyList"
          style="width: 100%"
          v-loading="loading_history"
        >
          <el-table-column
            prop="createTime"
            :label="$t('edpBackendReport.createTime')"
          >
          </el-table-column>

          <el-table-column
            prop="createFullName"
            :label="$t('edpBackendReport.createBy')"
          >
          </el-table-column>

          <el-table-column
            prop="createBy"
            :label="$t('edpBackendReport.createByCdsid')"
          >
          </el-table-column>

          <el-table-column prop="num" :label="$t('edpBackendReport.createNum')">
          </el-table-column>

          <el-table-column :label="$t('edpBackendReport.control')">
            <template slot-scope="scope">
              <el-button type="text" @click="seeReport(scope.row)">
                {{ $t("edpBackendReport.seeReport") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          background
          @current-change="handleCurrentChange_history"
          :current-page="currentPage_history"
          :page-size="pageSize_history"
          layout="total, prev, pager, next, jumper"
          :total="pageTotal_history"
        >
        </el-pagination>
      </section>
    </el-dialog>

    <!-- history detail -->
    <el-dialog
      class="edp-backend-dialogEl"
      v-if="reportHistoryDetail_dialogVisible"
      :title="$t('edpBackendReport.uploadHistoryDetail')"
      :visible.sync="reportHistoryDetail_dialogVisible"
      width="1000px"
    >
      <section class="edp-backend-conditionSearch">
        <div class="edp-backend-conditionSearch-square">
          <div class="edp-backend-conditionSearch-label">
            {{ $t("edpBackendReport.reportName") }}
          </div>

          <div class="edp-backend-conditionSearch-content">
            <el-input
              max="100"
              placeholder="请输入"
              v-model="reportHistoryDetail_searchParams.reportName"
              clearable
            />
          </div>
        </div>

        <div class="edp-backend-conditionSearch-square">
          <div class="edp-backend-conditionSearch-label">
            {{ $t("edpBackendReport.updateMemberName") }}
          </div>

          <div class="edp-backend-conditionSearch-content">
            <el-input
              max="100"
              placeholder="请输入"
              v-model="reportHistoryDetail_searchParams.createFullName"
              clearable
            />
          </div>
        </div>

        <div class="edp-backend-conditionSearch-square">
          <div class="edp-backend-conditionSearch-label">
            {{ $t("edpBackendReport.updateMemberCdsId") }}
          </div>

          <div class="edp-backend-conditionSearch-content">
            <el-input
              max="100"
              placeholder="请输入"
              v-model="reportHistoryDetail_searchParams.createBy"
              clearable
            />
          </div>
        </div>

        <div class="edp-backend-conditionSearch-square" style="width: 57%">
          <div class="edp-backend-conditionSearch-label">
            {{ $t("edpBackendReport.createTime") }}
          </div>

          <div class="edp-backend-conditionSearch-content">
            <el-date-picker
              v-model="reportHistoryDetail_timeRange"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetimerange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </div>

        <div
          class="edp-backend-conditionSearch-square conditionSearch-square-last"
        >
          <el-button
            size="small"
            @click="reportHistoryDetail_clearSearchParams"
            >{{ $t("edpBackendCommon.clear") }}</el-button
          >
          <el-button
            size="small"
            type="primary"
            @click="reportHistoryDetail_pageInit(1)"
            >{{ $t("edpBackendCommon.search") }}</el-button
          >
        </div>
      </section>

      <section class="edp-backend-common-table">
        <el-table
          stripe
          :data="reportHistoryDetail_list"
          style="width: 100%"
          v-loading="loading"
        >
          <el-table-column
            fixed
            prop="reportName"
            :label="$t('edpBackendReport.reportName')"
          >
          </el-table-column>

          <el-table-column
            prop="createFullName"
            :label="$t('edpBackendReport.updateMemberName')"
          ></el-table-column>

          <el-table-column
            prop="createBy"
            :label="$t('edpBackendReport.updateMemberCdsId')"
          ></el-table-column>

          <el-table-column
            width="180"
            prop="notEvaluated"
            :label="$t('edpBackendReport.createTime')"
          >
            <template slot-scope="scope">
              {{ scope.row.createTime || "--" }}
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          background
          @current-change="reportHistoryDetail_handleCurrentChange"
          :current-page="reportHistoryDetail_currentPage"
          :page-size="reportHistoryDetail_pageSize"
          layout="total, prev, pager, next, jumper"
          :total="reportHistoryDetail_pageTotal"
        >
        </el-pagination>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import edpUpload from "../../components/upload/edpUpload.vue";

export default {
  components: { edpUpload },
  name: "backendReport",
  data() {
    return {
      pageList: [],
      currentPage: 1,
      pageSize: 10,
      pageTotal: 0,
      loading: true,
      dialogVisible: false,

      timeRange: [],

      searchParams: {
        reportName: "",
        fullName: "",
        cdsid: "",
        createFullName: "",
        createBy: "",
      },
      fileListReal: [],
      subStatus: false,
      subData: [],

      dialogVisible_history: false,
      historyList: [],
      loading_history: true,

      currentPage_history: 1,
      pageSize_history: 10,
      pageTotal_history: 0,

      deleteDataListId: [],

      reportHistoryDetail_currentPage: 1,
      reportHistoryDetail_pageSize: 10,
      reportHistoryDetail_pageTotal: 0,
      reportHistoryDetail_loading: true,
      reportHistoryDetail_dialogVisible: false,
      reportHistoryDetail_timeRange: [],

      reportHistoryDetail_searchParams: {
        reportName: "",
        fullName: "",
        cdsid: "",
        createFullName: "",
        createBy: "",
      },
      reportHistoryDetail_list: [],
    };
  },
  methods: {
    ...mapActions({
      reportList: "backendReport/reportList",
      reportHistoryList: "backendReport/reportHistoryList",
      reportUpload: "backendReport/reportUpload",
      reportDelete: "backendReport/reportDelete",
      reportHistoryDetail: "backendReport/reportHistoryDetail",
      outputImage: "backendReport/outputImage",
    }),
    async pageInit(num) {
      this.loading = true;

      if (num) {
        this.currentPage = num;
      }

      let params = Object.assign(
        JSON.parse(JSON.stringify(this.searchParams)),
        {
          createTimeStart:
            this.timeRange && this.timeRange.length > 0
              ? this.timeRange[0]
              : "",
          createTimeEnd:
            this.timeRange && this.timeRange.length > 1
              ? this.timeRange[1]
              : "",
          current: this.currentPage,
          size: this.pageSize,
        }
      );

      let res = await this.reportList(params);

      this.pageList = res.data.data.records;

      this.pageTotal = res.data.data.total;

      this.loading = false;

      this.pageList.forEach((val) => {
        if (this.deleteDataListId.includes(val.id)) {
          this.$set(val, "selected", true);
        } else {
          this.$set(val, "selected", false);
        }
      });
    },
    handleCurrentChange(val) {
      this.pageInit(val);
    },
    clearSearchParams() {
      this.timeRange = [];
      for (var key in this.searchParams) {
        this.searchParams[key] = "";
      }

      this.pageInit(1);
    },
    handleSelectionChange(data) {
      let hasIndex = "";
      let hasId = this.deleteDataListId.some((val, e) => {
        if (val == data.id) {
          hasIndex = e;
          return true;
        }
      });

      if (hasId) {
        this.deleteDataListId.splice(hasIndex, 1);
      } else {
        this.deleteDataListId.push(data.id);
      }

      setTimeout(() => {
        this.pageList.some((val) => {
          this.deleteDataListId.includes(val.id)
            ? this.$set(val, "selected", true)
            : this.$set(val, "selected", false);
        });
      }, 0);
    },
    deleteRow(data) {
      if (data && data.id) {
        this.deleteDataListId = [data.id];
      }

      this.$confirm(
        this.$t("edpBackendCommon.actionConfirm"),
        this.$t("edpBackendCommon.noticeTitle"),
        {
          confirmButtonText: this.$t("edpBackendCommon.define"),
          cancelButtonText: this.$t("edpBackendCommon.canale"),
        }
      ).then(async () => {
        let res = await this.reportDelete({
          ids: this.deleteDataListId,
        });

        if (res.data.success) {
          this.$message({
            type: "success",
            message: this.$t("edpBackendCommon.actionSuccess"),
          });

          this.pageInit(1);
        }
      });
    },
    uploadDoc() {
      this.dialogVisible = true;
    },
    async reportHistoryDetail_outputImage() {
      this.$confirm(
        this.$t("edpBackendCommon.actionConfirm"),
        this.$t("edpBackendCommon.noticeTitle"),
        {
          confirmButtonText: this.$t("edpBackendCommon.define"),
          cancelButtonText: this.$t("edpBackendCommon.canale"),
        }
      ).then(async () => {
        let res = await this.outputImage();

        if (res.data.success) {
          this.$message({
            type: "success",
            message: this.$t("edpBackendCommon.actionSuccess"),
          });

          this.pageInit(1);
        }
      });
    },
    uploadSuccessFn(data) {
      this.subStatus = true;
      this.subData = data;
    },

    async dialogVisible_submit() {
      const loading = this.$loading({
        lock: true,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      if (this.subStatus) {
        let res = await this.reportUpload(this.subData);

        if (res.data.success) {
          this.$message({
            type: "success",
            message: this.$t("edpBackendCommon.actionSuccess"),
          });

          this.dialogVisible = false;

          loading.close();

          this.pageInit(1);
        }
      }
    },
    async historyInit(num) {
      this.loading_history = true;

      if (num) {
        this.currentPage_history = num;
      }

      let params = {
        current: this.currentPage_history,
        size: this.pageSize_history,
      };

      let res = await this.reportHistoryList(params);

      if (res.data && res.data.data) {
        this.historyList = res.data.data.records || [];

        this.pageTotal_history = res.data.data.total || 0;
      }

      this.loading_history = false;
    },
    seeHistory() {
      this.historyInit(1);
      this.dialogVisible_history = true;
    },
    handleCurrentChange_history(val) {
      this.historyInit(val);
    },
    seeReport(data) {
      for (var key in this.reportHistoryDetail_searchParams) {
        this.searchParams[key] = "";
      }

      this.reportHistoryDetail_timeRange = [data.createTime, data.createTime];

      this.reportHistoryDetail_searchParams.createBy = data.createBy;
      this.reportHistoryDetail_pageInit(1);

      this.dialogVisible_history = false;

      this.reportHistoryDetail_dialogVisible = true;
    },

    reportHistoryDetail_clearSearchParams() {
      this.reportHistoryDetail_timeRange = [];
      for (var key in this.reportHistoryDetail_searchParams) {
        this.reportHistoryDetail_searchParams[key] = "";
      }

      this.reportHistoryDetail_pageInit(1);
    },

    async reportHistoryDetail_pageInit(num) {
      this.reportHistoryDetail_loading = true;

      if (num) {
        this.reportHistoryDetail_currentPage = num;
      }

      let params = Object.assign(
        JSON.parse(JSON.stringify(this.reportHistoryDetail_searchParams)),
        {
          createTimeStart:
            this.reportHistoryDetail_timeRange &&
            this.reportHistoryDetail_timeRange.length > 0
              ? this.reportHistoryDetail_timeRange[0]
              : "",
          createTimeEnd:
            this.reportHistoryDetail_timeRange &&
            this.reportHistoryDetail_timeRange.length > 1
              ? this.reportHistoryDetail_timeRange[1]
              : "",
          current: this.reportHistoryDetail_currentPage,
          size: this.reportHistoryDetail_pageSize,
        }
      );

      let res = await this.reportHistoryDetail(params);

      this.reportHistoryDetail_list = res.data.data.records;

      this.reportHistoryDetail_pageTotal = res.data.data.total;

      this.reportHistoryDetail_loading = false;
    },
    reportHistoryDetail_handleCurrentChange(val) {
      this.reportHistoryDetail_pageInit(val);
    },
  },
  async mounted() {
    this.pageInit(1);
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
</style>
