<template>
  <div>
    <div id="edpUpLoadContainer">
      <el-upload
        ref="upload"
        action
        multiple
        :http-request="handleUpload"
        :limit="20"
        :file-list="fileList"
        :on-exceed="handleExceed"
        :before-upload="beforeUpload"
        :show-file-list="false"
      >
        <el-button type="primary" size="small">
          {{ $t("edpBackendReport.selectDoc") }}</el-button
        >
      </el-upload>

      <div class="edpUpLoadContainer-buttonSquare">
        <el-button type="primary" size="small" @click="aliStart">
          {{ $t("edpBackendReport.beginUpload") }}</el-button
        >

        <div
          class="edpUpLoadContainer-buttonSquare__opacity"
          v-if="!fileList || fileList.length == 0"
        ></div>
      </div>

      <div class="epdUpload-remarks">
        {{ $t("edpBackendReport.updateLimit") }}
      </div>
    </div>

    <p class="pt10" v-html="$t('edpBackendReport.nameRule')"></p>

    <div class="edpUpload-list" v-if="fileList && fileList.length > 0">
      <ul class="edpUpload-list__header">
        <li style="width: 35%">{{ $t("edpBackendReport.reportName") }}</li>
        <li style="width: 15%">{{ $t("edpBackendReport.reportSize") }}</li>
        <li style="width: 35%; text-align: center">
          {{ $t("edpBackendReport.uploadStatus") }}
        </li>
        <li style="width: 15%; text-align: center">
          {{ $t("edpBackendReport.control") }}
        </li>
      </ul>

      <ul
        v-for="(item, index) in fileList"
        :key="'uploadItem' + index"
        :id="item.id"
      >
        <li style="width: 35%">{{ item.name }}</li>
        <li style="width: 15%">{{ getSize(item.size) }}</li>
        <li style="width: 35%">
          <div class="edpUpload-progress">
            <div
              class="edpUpload-progress__bar"
              :style="'width: ' + item.process + '%'"
            ></div>
          </div>
        </li>
        <li style="width: 15%; text-align: center">
          <el-button type="text" @click="deleteItem(item, index)">
            {{ $t("edpBackendCommon.delete") }}</el-button
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { Toast } from "vant";
import upload from "../../common/fileUploadRp.js";

export default {
  name: "edpUpload",
  data() {
    return {
      fileList: [],
      accessid: "",
      dir: "",
      host: "",
      policyBase64: "",
      signature: "",
      expire: "",

      idLs: [],
    };
  },
  methods: {
    ...mapActions({
      reportCheck: "backendReport/reportCheck",
      reportUpload: "backendReport/reportUpload",
    }),
    getSize(size) {
      let data = "";
      data = (size / (1024 * 1024)).toFixed(2) + "MB";

      return data;
    },
    deleteItem(index) {
      this.fileList.splice(index, 1);
    },
    async handleUpload(op) {
      let thisFile = op.file;
      let listLength = this.fileList.length;

      let params = [];

      if (thisFile.name === this.fileList[listLength - 1].name) {
        this.fileList.forEach((val) => {
          params.push({ evaluationReportName: val.name });
        });

        let res = await this.reportCheck(params);

        if (res.data.success) {
          this.accessid = res.data.data.accessid;
          this.dir = res.data.data.dir;
          this.expire = parseInt(res.data.data.expire);
          this.host = res.data.data.host;
          this.policyBase64 = res.data.data.policy;
          this.signature = res.data.data.signature;
        } else {
          this.fileList = this.fileList.filter((val) => {
            return !this.idLs.includes(val.name);
          });
        }

        this.idLs = [];
      }
    },
    handleExceed() {
      Toast(this.$i18n.locale === "cn" ? "最多上传20个文件" : "Most 20");
    },
    async beforeUpload(file) {
      let sameSt = this.fileList.some((val) => {
        return file.name === val.name;
      });

      let fileArr = file.name.split("."),
        fileLength = fileArr.length,
        fileAfter = fileArr[fileLength - 1];

      if (this.getSize(file.size) > 2) {
        Toast(this.$i18n.locale === "cn" ? "文件太大了" : "File too large");

        return false;
      }

      if (fileAfter != "pdf") {
        Toast(
          this.$i18n.locale === "cn" ? "文件类型不正确" : "Wrong file type"
        );

        return false;
      }

      if (!sameSt) {
        this.fileList.push(file);
        this.idLs.push(file.name);
      } else {
        Toast(
          this.$i18n.locale === "cn" ? "这个文件已经上传过一遍了" : "Same File"
        );
      }
    },
    async aliStart() {
      let dir = `report`;

      let _this = this;

      let fileLength = this.fileList.length;

      this.fileList.forEach((val, e) => {
        upload(
          val,
          async (res) => {
            if (e == fileLength - 1) {
              let paramsSub = [];

              _this.fileList.forEach((val) => {
                paramsSub.push({
                  evaluationReportName: val.name,
                  reportFilePath: _this.dir + "" + val.name,
                });
              });

              _this.$emit("success", paramsSub);
            }
          },
          (err) => {
            Toast(_this.$i18n.locale === "cn" ? "上传失败" : "Upload Failed");
          },
          (res) => {
            val.process = res.percent;
            _this.$forceUpdate();
          },
          dir,
          [
            {
              evaluationReportName: val.name,
              reportFilePath: _this.dir + "" + val.name,
            },
          ]
        );
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edpUpload-list {
  width: 100%;

  ul {
    display: flex;
    width: 100%;
    padding-top: toPc(20);

    li {
      font-size: toPc(12);
      flex-shrink: 0;
      padding-right: toPc(20);
      word-break: break-all;
      word-wrap: break-word;

      &:last-child {
        padding-right: 0;
      }

      .el-button--text {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &.edpUpload-list__header {
      li {
        font-size: toPc(14);
        font-weight: bold;
      }
    }
  }
}
#edpUpLoadContainer {
  display: flex;
  width: 100%;
  align-items: center;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  padding: toPc(20) 0;
}
.epdUpload-remarks {
  font-size: toPc(12);
  color: #666;
  margin-left: toPc(15);
}
.edpUpload-progress {
  position: relative;
  width: 100%;
  height: toPc(10);
  background: #dedede;
  border-radius: toPc(4);
  overflow: hidden;
  top: toPc(4);

  .edpUpload-progress__bar {
    position: absolute;
    height: toPc(10);
    overflow: hidden;
    background: #4ac9cd;
    left: 0;
    top: 0;
  }
}
.edpUpLoadContainer-buttonSquare {
  position: relative;
  display: inline-block;
  margin-left: toPc(10);

  .edpUpLoadContainer-buttonSquare__opacity {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #fff;
    opacity: 0.4;
    border-radius: 3px;
  }
}
</style>
